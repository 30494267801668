<template>
  <div class="navCont" :class="isChange?'showBottomAnimation':'showTopAnimation'">
   <div class="nav" :style="'background:'+isColor+';transition: background-color 0.5s;'">
    <div class="logo">
        <img src="../assets/LOGO3.png" alt="">
    </div>
    <ul>
        <li v-for="(item,index) in navList" :key="index"  @click="navBtn(index)" :class="isShow[index]?'navShow':''">
            <router-link :to="{path:item.router}">{{item.name}}</router-link>
            <span></span>
            <ul :class="item.accseeShow?'accessNav':''">
                <li v-for="(childs,i) in item.child" :key="i">
                    <router-link :to="childs.childRouter">{{childs.name}}</router-link>
                </li>
            </ul>
        </li>
    </ul>
      <div class="vanSearch">
        <img src="../assets/newSearch.png" alt="导航搜索">
        <input
         v-model="searchValue"
          placeholder="输入搜索内容..."
        />
      <span class="button" @click="searchBtn">搜索</span>
      </div>
    <div class="moveLoge"></div>
    <div class="move">
      <van-search
        v-model="searchValue"
        show-action
        background="#000"
        placeholder="输入搜索内容..."
        v-show="showSearch"
      >
      </van-search>
      <img src="../assets/search.png" alt="搜索" class="moveSearch" @click="hiddenShow" v-show="!showSearch">
      <span class="searchBtn"  @click="searchBtn" v-show="showSearch">搜索</span>
      <!-- <img src="../assets/search.png" alt="搜索" class="moveSearch" @click="searchBtn" v-show="showSearch"> -->
      <img src="../assets/more (1).png" alt="更多" @click="showPopup">
      <van-popup v-model="popup" position="right" :style="{ width:'50vw', height: '100%' }">
        <p><router-link to="/">首页</router-link></p>
        <p><router-link to="/aboutUs">关于我们</router-link></p>
        <p><router-link to="/news">新闻中心</router-link></p>
        <p><router-link to="/solution">解决方案</router-link>
          <span>&nbsp;&nbsp;&nbsp;<router-link to="/solution">消毒供应服务</router-link></span>
          <span>&nbsp;&nbsp;&nbsp;<router-link to="/traceBack">追溯系统</router-link></span>
          <span>&nbsp;&nbsp;&nbsp;<router-link to="/cases">工程建设</router-link></span>
        </p>
        <p><router-link to="/joinus">招贤纳士</router-link></p>
        <p><router-link to="/support">服务支持</router-link></p>
      </van-popup>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  props:{
    nums:{
      type:String,
      default: '0'
    },
    isColor:{
      type:String,
      default: ''
    },
    isChange:{
      type:Boolean,
      default: true
    }
  },
  data () {
    return {
      navList: [{ name: '首页', router: '/', accseeShow: false },
        { name: '关于我们', router: '/aboutUs', accseeShow: false },
        { name: '新闻中心', router: '/news', accseeShow: false },
        { name: '解决方案', router: '/solution', accseeShow: true, child: [{ name: '消毒供应服务', childRouter: '/solution' }, { name: '追溯系统', childRouter: '/traceBack' }, { name: '工程建设', childRouter: '/cases' }] },
        { name: '招贤纳士', router: '/joinus', accseeShow: false },
        { name: '服务支持', router: '/support', accseeShow: false }],
      isShow:new Array(6),
      isfff:'',
      searchValue:'',
      popup:false,
      showSearch:false
    }
  },
  created () {
    const n = this.nums
    const arr = new Array(6)
    arr[n] = true
    this.isShow = arr
  },
  methods: {
    navBtn (index) {
      let show = JSON.parse(JSON.stringify(this.isShow))
      show = []
      show[index] = !show[index]
      this.isShow = show
    },
    searchBtn(){
      if (this.searchValue === '') return
      this.$router.push({
        path:'/news',
        query:{ title:this.searchValue }
      })
      location.reload()
    },
    showPopup(){
      this.popup = true
    },
    hiddenShow(){
      this.showSearch = !this.showSearch
    }
  }
}
</script>
<style lang="less">
@import './fn.less';
.showTopAnimation{
   opacity: 0;
}
.showBottomAnimation{
  opacity: 1;
}
.navCont{
       width: 100%;
       height: 5.20833vw;
       transition: all 0.2s linear;
}
   .nav{
       width: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding-left: 5.729166vw;
       padding-right: 5.729166vw;
       color: #101218;
       font-size: 1.04166vw;
       position: fixed;
       box-sizing: border-box;
       z-index: 200;
   }
   .move{
     display: none;
   }
   .logo{
       .vw(width,253);
       height: 5.260416vw;
       display: flex;
       justify-content: center;
       align-items: center;
       img{
           width: 10.5020vw;
          height: 90%;
           background-size: 100% 100%;
       }
   }
   .nav>ul{
      flex: 1;
      height: 5.260416vw;
      display: flex;
      justify-content: flex-start;
      margin-left: 5.20833vw;
      align-items: center;
   }
   .nav>ul>li{
            min-width: 5.20833vw;
            height: 110%;
            float: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 4.1666vw;
      }
      .nav>ul>li>a:hover{
        opacity: .6;
      }
   .nav>ul .navShow{
       a{
           color:#00538D;
           font-weight: 700;
       }
       span{
                display: inline-block;
                width: 0.5729166vw;
                height: 0.5729166vw;
                background: #00538D;
                border-radius: 50%;
                margin-top:0.520833vw;
                position: absolute;
                bottom: 0.520833vw;
            }
   }
    .nav>ul>li:hover .accessNav{
        height:7.1875vw;
        border: 1px solid #00538D;
    }
   .nav>ul>li .accessNav{
       width: 7.604166vw;
       height: 0px;
       border-radius: 0.83333vw;
       position: absolute;
       top: 5.46875vw;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-between;
       overflow: hidden;
       background: #fff;
       li{
           width: 100%;
           flex: 1;
           font-size: 1.041666vw;
           color:#101218;
           display: flex;
           align-items: center;
           justify-content: center;
       }
       li:hover{
           background: #00538D;
           a{
           color: #fff;
           }
       }
    }
    .nav_right{
      font-size: 1.04166vw;
      display: flex;
      align-items: center;
      .email{
        width: 1.145833vw;
        height: 0.9375vw;
        margin-right: 0.520833vw;
      }
      .search{
        width: 1.04166vw;
        height: 0.9895833vw;
        margin-left: 0.520833vw;
      }
    }
    .vanSearch{
      .vw(width,340);
      display: flex;
      align-items: center;
      position: relative;
      border: 0.05vw solid #00538D;
      .vw(border-radius,5);
      overflow: hidden;
      input{
        .vw(width,200);
        .vw(height,35);
        .vw(font-size,15);
        .vw(padding,0,0,0,40);
        border: 0.0vw solid #00538D;
       }
       img{
         .vw(width,20);
         .vw(height,19);
         position: absolute;
         .vw(left,10)
       }
      .button{
        .vw(width,80);
        .vw(height,35);
        text-align: center;
        .vw(line-height,35);
        border: 0;
        background: #00538D;
        color: #fff;
        .vw(font-size,18);
        cursor: pointer;
      }
    }
  @media screen and (max-width: 980px) {
    .navCont{
       width: 100%;
       height: 12vw;
    }
    .nav{
      background: black !important;
      height: 12vw;
      .logo{
          display: none;
      }
      ul{
        display: none;
      }
      .moveLoge{
        width: 24.73469vw;
        height: 8.040816vw;
        background: url('../assets/LOGO.png') no-repeat;
        background-size: 100% 100%;
      }
      .move{
        display: block;
        display: flex;
        align-items: center;
        img{
          .vvw(width,42);
          .vvw(height,34)
        }
        .moveSearch{
          .vvw(width,39);
          .vvw(height,37);
          .vvw(margin-right,20);
        }
        .van-search{
          flex: 1;
          padding: 0;
          margin-right: 0.5vw;
          .van-cell{
            .vvw(font-size,24);
            .vvw(padding,0,0,0,0);
          }
          .van-search__action{
            display: none;
          }
        }
        .van-field__body{
          i{
            display: none;
          }
        }
      }
      .vanSearch{
        display: none;
      }
    }
    .van-popup{
      padding: 10vw 5vw;
      box-sizing: border-box;
      .vvw(font-size,32);
      background-color: #000!important;
      p{
        padding-bottom: 4vw;
        padding-top: 4vw;
        border-bottom: 1px solid #aaa;
        display: flex;
        flex-direction: column;
        a{
        color: #aaa;
        }
        span{
          margin-top: 2vw;
        }
      }
    }
    .searchBtn{
      color: #fff;
      .vvw(font-size,36);
      margin-right: 1vw;
      margin-left: 1vw;
    }
  }
</style>
