// eslint-disable-next-line
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'

const http = axios.create({
  baseURL: 'https://www.laokenhealth.com',
  timeout: 10000
})

const imgHttp = 'https://oldken1.oss-cn-chengdu.aliyuncs.com'
export { http, imgHttp }

/**
 * @typedef R
 * @property {number} code
 * @property {string} msg
 * @property {string} time
 * @property {any} data
 */

/**
 * @param {AxiosRequestConfig} config
 * @return {Promise<AxiosResponse<R>>}
 */
export async function request (config) {
  try {
    const res = await http.request(config)
    if (res.data.code !== 1) {
      // noinspection ExceptionCaughtLocallyJS
      throw res.data
    }
    return res
  } catch (e) {
    console.error(e, 'occurred on request', config, '')
    alert(typeof e === 'string' ? e : ('message' in e ? e.message : ('msg' in e ? e.msg : e)))
    throw e
  }
}
